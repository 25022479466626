import { render, staticRenderFns } from "./WideBanner.vue?vue&type=template&id=26fb4149&scoped=true&"
import script from "./WideBanner.vue?vue&type=script&lang=ts&"
export * from "./WideBanner.vue?vue&type=script&lang=ts&"
import style0 from "./WideBanner.vue?vue&type=style&index=0&id=26fb4149&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26fb4149",
  null
  
)

export default component.exports